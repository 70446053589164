<template>
<div :key="`users_comp_${key}`">
    <div
        id="kt_subheader"
        class="kt-subheader kt-grid__item"
    >
        <div class="kt-container kt-container--fluid">
            <div class="kt-subheader__main">
                <h3
                    v-if="deviceType !== 'mobile' && deviceType !== 'tablet'"
                    class="kt-subheader__title"
                >
                    Users
                </h3>
                <div class="kt-input-icon kt-input-icon--right kt-subheader__search mt-1">
                    <input
                        v-model="searchString"
                        type="text"
                        class="form-control"
                        placeholder="Filter..."
                        @keyup="currentPage = 1"
                    >
                    <span class="kt-input-icon__icon kt-input-icon__icon--right">
                        <span>
                            <i class="flaticon2-search-1" />
                        </span>
                    </span>
                </div>
            </div>

            <div class="kt-subheader__toolbar">
                <div class="kt-subheader__wrapper">
                    <a
                        href="#"
                        class="btn"
                        :class="bulkUsers.length == 0 ? 'kt-subheader__btn-secondary' : 'btn-primary btn-bold'"
                        :disabled="bulkUsers.length == 0"
                        @click.stop.prevent="applyBulk"
                    >
                        <i class="flaticon2-settings" />
                        Bulk Apply Permissions
                    </a>
                    <router-link
                        :to="{ name: 'UserEdit', params: { schoolEmail: 'new' } }"
                        class="btn btn-label-success btn-bold"
                    >
                        <i class="flaticon2-user" />
                        Add New User
                    </router-link>
                </div>
            </div>
        </div>
    </div>

    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile kt-portlet kt-portlet--tabs">
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-toolbar">
                    <ul
                        class="nav nav-tabs nav-tabs-line nav-tabs-line-brand nav-tabs-line-2x nav-tabs-line-right nav-tabs-bold"
                        role="tablist"
                    >
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{
                                    active: filteredRole == 'School Admin',
                                }"
                                href="#"
                                @click.stop.prevent="selectRole('School Admin')"
                            >Administrators</a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{
                                    active: filteredRole == 'Teacher',
                                }"
                                href="#"
                                @click.stop.prevent="selectRole('Teacher')"
                            >Teachers</a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{ active: filteredRole == 'Staff' }"
                                href="#"
                                @click.stop.prevent="selectRole('Staff')"
                            >School Staff</a>
                        </li>
                    </ul>
                </div>
                <div class="kt-portlet__head-toolbar">
                    <div class="kt-portlet__head-actions mb-0 pt-3">
                        <a
                            v-if="schoolTerms.length > 1"
                            href="#"
                            class="btn btn-primary btn-sm btn-bold"
                            @click.stop.prevent="importAdmin"
                        >Transfer Users</a>
                    </div>
                </div>
            </div>
            <div class="table-button-container p-3 row" />
            <div v-if="!$_userMixins_isSchoolAdmin">
                <div class="alert alert-outline-danger show w-100">
                    <div class="alert-icon">
                        <i class="flaticon-warning" />
                    </div>
                    <div class="alert-text">
                        You do not have permission to access this page
                    </div>
                </div>
            </div>
            <div
                v-else
                class="kt-portlet__body pt-0"
            >
                <div class="table-responsive m-0">
                    <table
                        :key="`users_comp_table_${key}`"
                        class="table table-hover"
                    >
                        <thead>
                            <tr>
                                <td class="check">
                                    <label class="kt-checkbox kt-checkbox--single kt-checkbox--solid mb-0">
                                        <input
                                            v-model="selectAll"
                                            type="checkbox"
                                            value
                                            class="kt-checkable"
                                        >
                                        <span class="mt-2" />
                                    </label>
                                </td>
                                <td>
                                    <span @click.stop.prevent="columnSort('user')">User</span>
                                </td>
                                <td>
                                    <span @click.stop.prevent="columnSort('jobTitle')">Job Title</span>
                                </td>
                                <td>
                                    <span @click.stop.prevent="columnSort('schoolEmail')">School Email</span>
                                </td>
                                <td v-if="!$_userMixins_isGoogleDisabled">
                                    <span @click.stop.prevent="columnSort('googleEmail')">Google Email</span>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="thisUser in users"
                                :key="`${thisUser.vueId}_data-row_${key}`"
                            >
                                <td class="check">
                                    <label class="kt-checkbox kt-checkbox--single kt-checkbox--solid mb-0">
                                        <input
                                            v-model="bulkUsers"
                                            type="checkbox"
                                            :value="thisUser.userId"
                                            class="kt-checkable"
                                        >
                                        <span class="mt-2" />
                                    </label>
                                </td>
                                <td>
                                    <div class="media">
                                        <router-link
                                            v-if="thisUser.awsImageUrl"
                                            :to="{
                                                name: 'UserEdit',
                                                params: {
                                                    schoolEmail: thisUser.schoolEmail,
                                                },
                                            }"
                                            class="kt-media kt-media--xs mr-1"
                                        >
                                            <img
                                                :src="thisUser.awsImageUrl"
                                                alt="image"
                                            >
                                        </router-link>

                                        <div class="media-body">
                                            <router-link
                                                :to="{
                                                    name: 'UserEdit',
                                                    params: {
                                                        schoolEmail: thisUser.schoolEmail,
                                                    },
                                                }"
                                                class="kt-user-card-v2__email kt-link mt-1 ml-2"
                                            >
                                                {{ thisUser.lastName }},
                                                {{ thisUser.firstName }}
                                            </router-link>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div>{{ thisUser.jobTitle }}</div>
                                </td>
                                <td
                                    class="email"
                                    :class="{'googledisabledemail': $_userMixins_isGoogleDisabled}"
                                >
                                    <div>{{ thisUser.schoolEmail }}</div>
                                </td>
                                <td
                                    v-if="!$_userMixins_isGoogleDisabled"
                                    class="email"
                                >
                                    <div>{{ thisUser.googleEmail }}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <UserPermissionsModal
        ref="user-permission-modal"
        :users="bulkUsersFormatted"
    />

    <UserImportModal
        v-if="schoolTerms.length > 1"
        ref="user-import-modal"
    />
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import userMixins from '../../store/mixins/userMixins';
import UserImportModal from './UserImportModal.vue';
import UserPermissionsModal from './UserPermissionsModal.vue';

export default Vue.extend({
    name: 'ManageUsers',
    components: {
        UserImportModal,
        UserPermissionsModal,
    },
    mixins: [userMixins],
    data() {
        return {
            key: 1,
            filter: '',
            filteredRole: 'School Admin',
            roles: ['School Admin', 'Staff', 'Teacher'],
            saving: false,
            searchString: '',
            refreshing: false,
            bulkUsers: [],
            sortOrder: {
                user: 'ascending',
                jobTitle: 'ascending',
                schoolEmail: 'ascending',
                googleEmail: 'ascending',
            },
            sortType: 'user',
        };
    },
    computed: {
        ...mapState({
            deviceType: (state) => state.deviceType,
            googleEmail: (state) => state.user.googleEmail,
            teachers: (state) => state.database.teachers,
            staffUsers: (state) => state.database.users,
            schoolTerms: (state) => state.database.schoolTerms,
        }),
        selectAll: {
            get() {
                const { bulkUsers } = this;
                return this.users.every((u) => bulkUsers.includes(u.userId));
            },
            set(value) {
                const bulkUsers = [];
                if (value) {
                    this.users.forEach((user) => {
                        bulkUsers.push(user.userId);
                    });
                }
                this.bulkUsers = bulkUsers;
            },
        },
        noData() {
            return Boolean((this.users || []).length == 0);
        },
        totalRecords() {
            return this.users.length;
        },
        bulkUsersFormatted() {
            const { staffUsers } = this;
            return this.bulkUsers.map((userId) => {
                const user = staffUsers.find((u) => u.userId == userId);
                return user;
            });
        },
        users() {
            const { filteredRole, teachers } = this;
            const searchString = (this.searchString || '').toLowerCase();
            const googleDisabled = this.$_userMixins_isGoogleDisabled;
            const arraySortOrder = (a, b) => {
                let result;
                const type = this.sortType;
                if (a.sort < b.sort) {
                    result = this.sortOrder[type] === 'ascending' ? -1 : 1;
                }
                if (a.sort > b.sort) {
                    result = this.sortOrder[type] === 'ascending' ? 1 : -1;
                }
                return result || 0;
            };

            return this.staffUsers
                .map((u) => {
                    const user = { ...u }; // new pointer
                    user.vueId = `user_${u.userId}`;
                    switch (this.sortType) {
                    case 'user':
                        user.sort = `${u.lastName}${u.firstName}`;
                        break;
                    case 'jobTitle':
                        user.sort = u.jobTitle;
                        break;
                    case 'schoolEmail':
                        user.sort = u.schoolEmail;
                        break;
                    case 'googleEmail':
                        user.sort = u.googleEmail;
                        break;
                    default:
                        user.sort = `${u.lastName}${u.firstName}`;
                    }
                    user.search = `${u.lastName} ${u.firstName} ${u.googleEmail} ${u.schoolEmail}`.toLowerCase();
                    user.awsImageUrl = null;
                    const teacher = teachers.find((t) => t.schoolEmail == u.schoolEmail);
                    if (teacher && teacher.awsImageUrl) {
                        user.awsImageUrl = teacher.awsImageUrl;
                    }
                    return user;
                })
                .filter((t) => {
                    if (googleDisabled) {
                        return t.schoolEmail && t.roleName == filteredRole && t.userDeleted === false && t.roleDeleted === false;
                    }
                    return t.schoolEmail && t.roleName == filteredRole && t.userDeleted === false && t.roleDeleted === false;
                })
                .filter((t) => (searchString ? t.search.indexOf(searchString.toLowerCase()) > -1 : true))
                .sort(arraySortOrder);
        },
    },
    watch: {
        staffUsers: {
            handler() {
                this.key += 1;
            },
            deep: true,
        },
    },
    methods: {
        columnSort(type) {
            this.sortType = type;
            this.sortOrder[type] = this.sortOrder[type] === 'ascending' ? 'descending' : 'ascending';
            Object.keys(this.sortOrder).forEach((ele) => {
                if (ele !== type) this.sortOrder[ele] = 'init';
            });
        },
        importAdmin() {
            this.$refs['user-import-modal'].open();
        },
        applyBulk() {
            if (this.bulkUsers.length == 0) return;
            this.$refs['user-permission-modal'].open();
        },
        selectRole(role) {
            this.filteredRole = role;
        },
        showNewStaffModal() {
            const v = this;
            v.selectedStaffUser = {
                userId: null,
                userName: null,
                schoolEmail: null,
                unlinkedGoogleEmail: null,
                googleEmail: null,
                firstName: null,
                lastName: null,
                jobTitle: null,
                userDeleted: null,
            };
            this.$refs['add-user-modal'].open();
        },
        first() {
            this.currentPage = 1;
        },
        last() {
            this.currentPage = this.totalPages;
        },
        previous() {
            if (this.currentPage > 1) {
                this.currentPage -= 1;
            }
        },
        next() {
            if (this.currentPage < this.totalPages) {
                this.currentPage += 1;
            }
        },
    },
});
</script>

<style scoped>
enable-label {
    font-weight: 300;
}

thead span {
    cursor: pointer;
}

td {
    vertical-align: middle;
}
td.check {
    width: 24px;
    height: 24px;
}

td.check label {
    width: 24px;
    height: 24px;
}
td.email {
    width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
}
td.email div {
    max-width: 142px;
    overflow: hidden;
    text-overflow: ellipsis;
}
td.googledisabledemail {
    width: 240px !important;
}
td.googledisabledemail div {
    max-width: 240px !important;
}
a.kt-link--state {
    line-height: 20px;
    height: 20px;
    overflow: hidden;
}

td.cell--user {
    min-width: 400px;
}

td.cell--controls {
    min-width: 200px;
}

.alignment {
    align-self: right;
}
</style>
