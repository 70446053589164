var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "import-user-modal",
    attrs: { size: "lg", centered: "" },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function ({ close }) {
          return [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v(" Import Users "),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close",
              },
              on: {
                click: function ($event) {
                  return close()
                },
              },
            }),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _vm.selectedTermSchoolTermId
              ? _c("div", { staticClass: "modal-body" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "form-control-label" }, [
                      _vm._v(" Import from School Term: "),
                    ]),
                    _vm.schoolTerms
                      ? _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedTermSchoolTermId,
                                expression: "selectedTermSchoolTermId",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.selectedTermSchoolTermId = $event.target
                                  .multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(_vm.schoolTerms, function (schoolTerm) {
                            return _c(
                              "option",
                              {
                                key: `schoolTerm_${schoolTerm.schoolTermId}`,
                                domProps: {
                                  value: schoolTerm.schoolTermId,
                                  selected:
                                    schoolTerm.schoolTermId ===
                                    _vm.selectedTermSchoolTermId,
                                },
                              },
                              [_vm._v(" " + _vm._s(schoolTerm.name) + " ")]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("School Admins:")]),
                    _vm.selectedTerm && _vm.adminUsers.length > 0
                      ? _c(
                          "div",
                          { staticClass: "kt-checkbox-inline" },
                          _vm._l(_vm.adminUsers, function (user) {
                            return _c(
                              "label",
                              {
                                key: `user_${_vm.selectedTerm.schoolTermId}_${user.userRoleId}`,
                                staticClass: "kt-checkbox mr-5",
                                class: !_vm.users.includes(user.userRoleId)
                                  ? ""
                                  : "kt-checkbox--solid kt-checkbox--brand kt-font-primary kt-font-bold",
                              },
                              [
                                _c("input", {
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: _vm.users.includes(
                                      user.userRoleId
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.toggle(user)
                                    },
                                  },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(user.lastName) +
                                    ", " +
                                    _vm._s(user.firstName) +
                                    " "
                                ),
                                _c("br"),
                                _vm._v(" " + _vm._s(user.userName) + " "),
                                _c("span", { staticClass: "mt-2" }),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("School Staff:")]),
                    _vm.selectedTerm && _vm.staffUsers.length > 0
                      ? _c(
                          "div",
                          { staticClass: "kt-checkbox-inline" },
                          _vm._l(_vm.staffUsers, function (user) {
                            return _c(
                              "label",
                              {
                                key: `user_${_vm.selectedTerm.schoolTermId}_${user.userRoleId}`,
                                staticClass: "kt-checkbox mr-5",
                                class: !_vm.users.includes(user.userRoleId)
                                  ? ""
                                  : "kt-checkbox--solid kt-checkbox--brand kt-font-primary kt-font-bold",
                              },
                              [
                                _c("input", {
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: _vm.users.includes(
                                      user.userRoleId
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggle(user)
                                    },
                                  },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(user.lastName) +
                                    ", " +
                                    _vm._s(user.firstName) +
                                    " "
                                ),
                                _c("br"),
                                _vm._v(" " + _vm._s(user.userName) + " "),
                                _c("span", { staticClass: "mt-2" }),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-primary alignment pull-right",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  disabled: _vm.saving || _vm.users.length == 0,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.save()
                  },
                },
              },
              [_vm._v(" Import " + _vm._s(_vm.users.length) + " users ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }