<template>
<!-- permissions modal -->
<b-modal

    ref="import-user-modal"
    size="lg"
    centered
>
    <template #modal-header="{ close }">
        <h5 class="modal-title">
            Import Users
        </h5>
        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="close()"
        />
    </template>

    <template #default>
        <div
            v-if="selectedTermSchoolTermId"
            class="modal-body"
        >
            <div class="form-group">
                <label class="form-control-label">
                    Import from School Term:
                </label>
                <select
                    v-if="schoolTerms"
                    v-model="selectedTermSchoolTermId"
                    class="form-control"
                >
                    <option
                        v-for="schoolTerm in schoolTerms"
                        :key="`schoolTerm_${schoolTerm.schoolTermId}`"
                        :value="schoolTerm.schoolTermId"
                        :selected="schoolTerm.schoolTermId === selectedTermSchoolTermId"
                    >
                        {{ schoolTerm.name }}
                    </option>
                </select>
            </div>

            <div class="form-group">
                <label>School Admins:</label>
                <div
                    v-if="selectedTerm && adminUsers.length > 0"
                    class="kt-checkbox-inline"
                >
                    <label
                        v-for="user in adminUsers"
                        :key="`user_${selectedTerm.schoolTermId}_${user.userRoleId}`"
                        class="kt-checkbox mr-5"
                        :class="!users.includes(user.userRoleId) ? '' : 'kt-checkbox--solid kt-checkbox--brand kt-font-primary kt-font-bold'"
                    >
                        <input
                            :checked="users.includes(user.userRoleId)"
                            type="checkbox"
                            @change="toggle(user)"
                        >

                        {{ user.lastName }}, {{ user.firstName }}
                        <br>
                        {{ user.userName }}
                        <span class="mt-2" />
                    </label>
                </div>
            </div>
            <div class="form-group">
                <label>School Staff:</label>
                <div
                    v-if="selectedTerm && staffUsers.length > 0"
                    class="kt-checkbox-inline"
                >
                    <label
                        v-for="user in staffUsers"
                        :key="`user_${selectedTerm.schoolTermId}_${user.userRoleId}`"
                        class="kt-checkbox mr-5"
                        :class="!users.includes(user.userRoleId) ? '' : 'kt-checkbox--solid kt-checkbox--brand kt-font-primary kt-font-bold'"
                    >
                        <input
                            :checked="users.includes(user.userRoleId)"
                            type="checkbox"
                            @click="toggle(user)"
                        >

                        {{ user.lastName }}, {{ user.firstName }}
                        <br>
                        {{ user.userName }}
                        <span class="mt-2" />
                    </label>
                </div>
            </div>
        </div>
    </template>
    <template #modal-footer>
        <button
            type="button"
            class="btn btn-primary alignment pull-right"
            data-dismiss="modal"
            :disabled="saving || users.length == 0"
            @click.stop.prevent="save()"
        >
            Import {{ users.length }} users
        </button>
    </template>
</b-modal>
</template>

<script>
import { mapState } from 'vuex';
import * as network from '../../network';
// import Types from '../../store/Types';

export default {
    name: 'UserImportModal',
    data() {
        return {
            saving: false,
            selectedTermSchoolTermId: 0,
            schoolStaff: [],
            users: [],
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        adminUsers() {
            return this.schoolStaff.filter((s) => s.roleName == 'School Admin');
        },
        staffUsers() {
            return this.schoolStaff.filter((s) => s.roleName != 'School Admin');
        },
        schoolTerms() {
            const { schoolTermId } = this.$store.state.user.school;
            const sortOn = (a, b) => ((`${a.name}` > `${b.name}`) ? 1 : -1);
            return this.$store.state.database.schoolTerms
                .filter((t) => t.schoolTermId !== schoolTermId)
                .sort(sortOn);
        },
        selectedTerm() {
            const { selectedTermSchoolTermId } = this;
            if (!selectedTermSchoolTermId) return null;
            return this.$store.state.database.schoolTerms
                .find((t) => t.schoolTermId === selectedTermSchoolTermId) || null;
        },
    },
    watch: {
        selectedTerm() {
            const { selectedTerm, showError } = this;
            if (!selectedTerm) return;

            const { schoolId, schoolTermId } = selectedTerm;
            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                },
            };

            network.users.listUsersForSchool(params, (err, res) => {
                if (err) return showError(`Error loading users in ${selectedTerm.name}`);
                const sortOn = (a, b) => ((`${a.roleName}${a.lastName}${a.firstName}` > `${b.roleName}${b.lastName}${b.firstName}`) ? 1 : -1);
                this.schoolStaff = res.users
                    .sort(sortOn);
            });
        },
    },
    methods: {
        open() {
            if (this.schoolTerms.length > 0) {
                const [firstTerm] = this.schoolTerms;
                this.selectedTermSchoolTermId = firstTerm.schoolTermId;
            }
            this.$refs['import-user-modal'].show();
        },
        toggle(user) {
            const exists = this.users.find((userRoleId) => userRoleId === user.userRoleId);
            if (exists) {
                this.users = this.users.filter((userRoleId) => userRoleId !== user.userRoleId);
            } else {
                this.users.push(user.userRoleId);
            }
        },
        save() {
            if (this.saving) return;
            this.saving = true;

            const v = this;
            const userRoles = v.users.slice();
            const { schoolId, schoolTermId } = v.user.school;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                },
                body: {
                    from: { schoolTermId: v.selectedTermSchoolTermId },
                    userRoles,
                },
            };
            network.users.bulkUserTransfer(params, (err) => {
                if (err) return v.showError(err);
                window.location.reload();
            });
        },
    },
};
</script>
