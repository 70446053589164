var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { key: `users_comp_${_vm.key}` },
    [
      _c(
        "div",
        {
          staticClass: "kt-subheader kt-grid__item",
          attrs: { id: "kt_subheader" },
        },
        [
          _c("div", { staticClass: "kt-container kt-container--fluid" }, [
            _c("div", { staticClass: "kt-subheader__main" }, [
              _vm.deviceType !== "mobile" && _vm.deviceType !== "tablet"
                ? _c("h3", { staticClass: "kt-subheader__title" }, [
                    _vm._v(" Users "),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "kt-input-icon kt-input-icon--right kt-subheader__search mt-1",
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchString,
                        expression: "searchString",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "Filter..." },
                    domProps: { value: _vm.searchString },
                    on: {
                      keyup: function ($event) {
                        _vm.currentPage = 1
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.searchString = $event.target.value
                      },
                    },
                  }),
                  _vm._m(0),
                ]
              ),
            ]),
            _c("div", { staticClass: "kt-subheader__toolbar" }, [
              _c(
                "div",
                { staticClass: "kt-subheader__wrapper" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn",
                      class:
                        _vm.bulkUsers.length == 0
                          ? "kt-subheader__btn-secondary"
                          : "btn-primary btn-bold",
                      attrs: { href: "#", disabled: _vm.bulkUsers.length == 0 },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.applyBulk.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "flaticon2-settings" }),
                      _vm._v(" Bulk Apply Permissions "),
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-label-success btn-bold",
                      attrs: {
                        to: {
                          name: "UserEdit",
                          params: { schoolEmail: "new" },
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "flaticon2-user" }),
                      _vm._v(" Add New User "),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "kt-portlet kt-portlet--mobile kt-portlet kt-portlet--tabs",
            },
            [
              _c("div", { staticClass: "kt-portlet__head" }, [
                _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                  _c(
                    "ul",
                    {
                      staticClass:
                        "nav nav-tabs nav-tabs-line nav-tabs-line-brand nav-tabs-line-2x nav-tabs-line-right nav-tabs-bold",
                      attrs: { role: "tablist" },
                    },
                    [
                      _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            class: {
                              active: _vm.filteredRole == "School Admin",
                            },
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.selectRole("School Admin")
                              },
                            },
                          },
                          [_vm._v("Administrators")]
                        ),
                      ]),
                      _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            class: {
                              active: _vm.filteredRole == "Teacher",
                            },
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.selectRole("Teacher")
                              },
                            },
                          },
                          [_vm._v("Teachers")]
                        ),
                      ]),
                      _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            class: { active: _vm.filteredRole == "Staff" },
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.selectRole("Staff")
                              },
                            },
                          },
                          [_vm._v("School Staff")]
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                  _c(
                    "div",
                    { staticClass: "kt-portlet__head-actions mb-0 pt-3" },
                    [
                      _vm.schoolTerms.length > 1
                        ? _c(
                            "a",
                            {
                              staticClass: "btn btn-primary btn-sm btn-bold",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.importAdmin.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v("Transfer Users")]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "table-button-container p-3 row" }),
              !_vm.$_userMixins_isSchoolAdmin
                ? _c("div", [_vm._m(1)])
                : _c("div", { staticClass: "kt-portlet__body pt-0" }, [
                    _c("div", { staticClass: "table-responsive m-0" }, [
                      _c(
                        "table",
                        {
                          key: `users_comp_table_${_vm.key}`,
                          staticClass: "table table-hover",
                        },
                        [
                          _c("thead", [
                            _c("tr", [
                              _c("td", { staticClass: "check" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "kt-checkbox kt-checkbox--single kt-checkbox--solid mb-0",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.selectAll,
                                          expression: "selectAll",
                                        },
                                      ],
                                      staticClass: "kt-checkable",
                                      attrs: { type: "checkbox", value: "" },
                                      domProps: {
                                        checked: Array.isArray(_vm.selectAll)
                                          ? _vm._i(_vm.selectAll, "") > -1
                                          : _vm.selectAll,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.selectAll,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = "",
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.selectAll = $$a.concat([
                                                  $$v,
                                                ]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.selectAll = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.selectAll = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _c("span", { staticClass: "mt-2" }),
                                  ]
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.columnSort("user")
                                      },
                                    },
                                  },
                                  [_vm._v("User")]
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.columnSort("jobTitle")
                                      },
                                    },
                                  },
                                  [_vm._v("Job Title")]
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.columnSort("schoolEmail")
                                      },
                                    },
                                  },
                                  [_vm._v("School Email")]
                                ),
                              ]),
                              !_vm.$_userMixins_isGoogleDisabled
                                ? _c("td", [
                                    _c(
                                      "span",
                                      {
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.columnSort("googleEmail")
                                          },
                                        },
                                      },
                                      [_vm._v("Google Email")]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.users, function (thisUser) {
                              return _c(
                                "tr",
                                {
                                  key: `${thisUser.vueId}_data-row_${_vm.key}`,
                                },
                                [
                                  _c("td", { staticClass: "check" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "kt-checkbox kt-checkbox--single kt-checkbox--solid mb-0",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.bulkUsers,
                                              expression: "bulkUsers",
                                            },
                                          ],
                                          staticClass: "kt-checkable",
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            value: thisUser.userId,
                                            checked: Array.isArray(
                                              _vm.bulkUsers
                                            )
                                              ? _vm._i(
                                                  _vm.bulkUsers,
                                                  thisUser.userId
                                                ) > -1
                                              : _vm.bulkUsers,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.bulkUsers,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = thisUser.userId,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.bulkUsers = $$a.concat(
                                                      [$$v]
                                                    ))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.bulkUsers = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.bulkUsers = $$c
                                              }
                                            },
                                          },
                                        }),
                                        _c("span", { staticClass: "mt-2" }),
                                      ]
                                    ),
                                  ]),
                                  _c("td", [
                                    _c(
                                      "div",
                                      { staticClass: "media" },
                                      [
                                        thisUser.awsImageUrl
                                          ? _c(
                                              "router-link",
                                              {
                                                staticClass:
                                                  "kt-media kt-media--xs mr-1",
                                                attrs: {
                                                  to: {
                                                    name: "UserEdit",
                                                    params: {
                                                      schoolEmail:
                                                        thisUser.schoolEmail,
                                                    },
                                                  },
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: thisUser.awsImageUrl,
                                                    alt: "image",
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "media-body" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                staticClass:
                                                  "kt-user-card-v2__email kt-link mt-1 ml-2",
                                                attrs: {
                                                  to: {
                                                    name: "UserEdit",
                                                    params: {
                                                      schoolEmail:
                                                        thisUser.schoolEmail,
                                                    },
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(thisUser.lastName) +
                                                    ", " +
                                                    _vm._s(thisUser.firstName) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("td", [
                                    _c("div", [
                                      _vm._v(_vm._s(thisUser.jobTitle)),
                                    ]),
                                  ]),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "email",
                                      class: {
                                        googledisabledemail:
                                          _vm.$_userMixins_isGoogleDisabled,
                                      },
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(thisUser.schoolEmail)),
                                      ]),
                                    ]
                                  ),
                                  !_vm.$_userMixins_isGoogleDisabled
                                    ? _c("td", { staticClass: "email" }, [
                                        _c("div", [
                                          _vm._v(_vm._s(thisUser.googleEmail)),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ]),
                  ]),
            ]
          ),
        ]
      ),
      _c("UserPermissionsModal", {
        ref: "user-permission-modal",
        attrs: { users: _vm.bulkUsersFormatted },
      }),
      _vm.schoolTerms.length > 1
        ? _c("UserImportModal", { ref: "user-import-modal" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "span",
      { staticClass: "kt-input-icon__icon kt-input-icon__icon--right" },
      [_c("span", [_c("i", { staticClass: "flaticon2-search-1" })])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "alert alert-outline-danger show w-100" }, [
      _c("div", { staticClass: "alert-icon" }, [
        _c("i", { staticClass: "flaticon-warning" }),
      ]),
      _c("div", { staticClass: "alert-text" }, [
        _vm._v(" You do not have permission to access this page "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }