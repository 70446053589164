var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "edit-permissions-modal",
    attrs: { size: "lg", centered: "" },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function ({ close }) {
          return [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v(" Bulk Apply Permissions "),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close",
              },
              on: {
                click: function ($event) {
                  return close()
                },
              },
            }),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c("div", { staticClass: "modal-body" }, [
              _c(
                "form",
                _vm._l(_vm.userPermissions, function (value, name) {
                  return _c(
                    "div",
                    { key: `permission_${name}`, staticClass: "form-group" },
                    [
                      name == "accessAllStudentPortfolios"
                        ? _c("label", { staticClass: "ml-2 pb-2" }, [
                            _vm._v("Allow access to all Student Portfolios"),
                          ])
                        : name == "accessAllTeacherPortfolios"
                        ? _c("label", { staticClass: "ml-2 pb-2" }, [
                            _vm._v("Allow access to all Teacher Portfolios"),
                          ])
                        : name == "accessAllAnecdotalEvents"
                        ? _c("label", { staticClass: "ml-2 pb-2" }, [
                            _vm._v("Allow access to all Anecdotal Events"),
                          ])
                        : name == "editSchoolAnecdotalEvents"
                        ? _c("label", { staticClass: "ml-2 pb-2" }, [
                            _vm._v(
                              "Can edit all anecdotals events in the school"
                            ),
                          ])
                        : name == "canChooseGradeTemplate"
                        ? _c("label", { staticClass: "ml-2 pb-2" }, [
                            _vm._v(
                              "Can choose from available grade templates for courses taught."
                            ),
                          ])
                        : name == "canManagePurchaseOrders"
                        ? _c("label", { staticClass: "ml-2 pb-2" }, [
                            _vm._v("Can manage purchase orders"),
                          ])
                        : name == "canSubmitBugReports"
                        ? _c("label", { staticClass: "ml-2 pb-2" }, [
                            _vm._v("Can submit bug reports"),
                          ])
                        : name == "canManageCourses"
                        ? _c("label", { staticClass: "ml-2 pb-2" }, [
                            _vm._v("Can manage courses and rostering"),
                          ])
                        : name == "canMessageSchoolUsers"
                        ? _c("label", { staticClass: "ml-2 pb-2" }, [
                            _vm._v("Can send communications to school users"),
                          ])
                        : name == "canMessageHomeUsers"
                        ? _c("label", { staticClass: "ml-2 pb-2" }, [
                            _vm._v("Can send communications to home users"),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "kt-radio-inline ml-4" }, [
                        _c(
                          "label",
                          { staticClass: "kt-radio kt-radio--solid" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.userPermissions[`${name}`],
                                  expression: "userPermissions[`${name}`]",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                name: "'noPermission' + name",
                                "true-value": "null",
                                "false-value": "!null",
                              },
                              domProps: {
                                checked: !value,
                                checked: Array.isArray(
                                  _vm.userPermissions[`${name}`]
                                )
                                  ? _vm._i(
                                      _vm.userPermissions[`${name}`],
                                      null
                                    ) > -1
                                  : _vm._q(
                                      _vm.userPermissions[`${name}`],
                                      "null"
                                    ),
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.userPermissions[`${name}`],
                                    $$el = $event.target,
                                    $$c = $$el.checked ? "null" : "!null"
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.userPermissions,
                                          `${name}`,
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.userPermissions,
                                          `${name}`,
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.userPermissions,
                                      `${name}`,
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" No Change "),
                            _c("span"),
                          ]
                        ),
                        _c(
                          "label",
                          {
                            staticClass:
                              "kt-radio kt-radio--tick kt-radio--brand",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.userPermissions[`${name}`],
                                  expression: "userPermissions[`${name}`]",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                name: "'addPermission' + name",
                                "true-value": "true",
                                "false-value": "!true",
                              },
                              domProps: {
                                checked: value,
                                checked: Array.isArray(
                                  _vm.userPermissions[`${name}`]
                                )
                                  ? _vm._i(
                                      _vm.userPermissions[`${name}`],
                                      null
                                    ) > -1
                                  : _vm._q(
                                      _vm.userPermissions[`${name}`],
                                      "true"
                                    ),
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.userPermissions[`${name}`],
                                    $$el = $event.target,
                                    $$c = $$el.checked ? "true" : "!true"
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.userPermissions,
                                          `${name}`,
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.userPermissions,
                                          `${name}`,
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.userPermissions,
                                      `${name}`,
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" Add Permission "),
                            _c("span"),
                          ]
                        ),
                        _c(
                          "label",
                          {
                            staticClass:
                              "kt-radio kt-radio--tick kt-radio--danger",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.userPermissions[`${name}`],
                                  expression: "userPermissions[`${name}`]",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                name: "'removePermission' + name",
                                "true-value": "false",
                                "false-value": "!false",
                              },
                              domProps: {
                                checked: !value,
                                checked: Array.isArray(
                                  _vm.userPermissions[`${name}`]
                                )
                                  ? _vm._i(
                                      _vm.userPermissions[`${name}`],
                                      null
                                    ) > -1
                                  : _vm._q(
                                      _vm.userPermissions[`${name}`],
                                      "false"
                                    ),
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.userPermissions[`${name}`],
                                    $$el = $event.target,
                                    $$c = $$el.checked ? "false" : "!false"
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.userPermissions,
                                          `${name}`,
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.userPermissions,
                                          `${name}`,
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.userPermissions,
                                      `${name}`,
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" Remove Permission "),
                            _c("span"),
                          ]
                        ),
                      ]),
                      name == "accessAllTeacherPortfolios"
                        ? _c(
                            "span",
                            { staticClass: "form-text text-muted ml-4 pt-2" },
                            [
                              _vm._v(
                                "Read only access to all teacher portfolio pages"
                              ),
                            ]
                          )
                        : name == "accessAllAnecdotalEvents"
                        ? _c(
                            "span",
                            { staticClass: "form-text text-muted ml-4 pt-2" },
                            [
                              _vm._v(
                                " This allows a user to see all non-confidential anecdotals created in this school. The user may not have permission to access all anecdotal participants, if porfolio access is not also granted. "
                              ),
                            ]
                          )
                        : name == "editSchoolAnecdotalEvents"
                        ? _c(
                            "span",
                            { staticClass: "form-text text-muted ml-4 pt-2" },
                            [
                              _vm._v(
                                " This user can edit any and all anecdotals they have permission to view. "
                              ),
                            ]
                          )
                        : name == "canChooseGradeTemplate"
                        ? _c(
                            "span",
                            { staticClass: "form-text text-muted ml-4 pt-2" },
                            [
                              _vm._v(
                                " If this user teaches courses, then they can choose a grade template from school templates "
                              ),
                            ]
                          )
                        : name == "canManagePurchaseOrders"
                        ? _c(
                            "span",
                            { staticClass: "form-text text-muted ml-4 pt-2" },
                            [
                              _vm._v(
                                " Typically this permission is given to the payroll secretary or whoever is the billing manager at your school. "
                              ),
                            ]
                          )
                        : name == "canSubmitBugReports"
                        ? _c(
                            "span",
                            { staticClass: "form-text text-muted ml-4 pt-2" },
                            [
                              _vm._v(
                                " This allows a user to create and send bug reports to SyncGrades Support. "
                              ),
                            ]
                          )
                        : name == "canManageCourses"
                        ? _c(
                            "span",
                            { staticClass: "form-text text-muted ml-4 pt-2" },
                            [
                              _vm._v(
                                " This allows a user to create, delete, and restore courses and add/remove students from their courses. "
                              ),
                            ]
                          )
                        : name == "canMessageSchoolUsers"
                        ? _c(
                            "span",
                            { staticClass: "form-text text-muted ml-4 pt-2" },
                            [
                              _vm._v(
                                " This allows a user to initiate communication/messages with other staff members "
                              ),
                            ]
                          )
                        : name == "canMessageHomeUsers"
                        ? _c(
                            "span",
                            { staticClass: "form-text text-muted ml-4 pt-2" },
                            [
                              _vm._v(
                                " This allows a user to initiate communication/messages with guardians and students "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
              _c("h6", [
                _vm._v(
                  " Applying to the following " +
                    _vm._s(_vm.users.length) +
                    " selected " +
                    _vm._s(_vm.users.length > 1 ? "users" : "user") +
                    ": "
                ),
              ]),
              _c("ul", { staticClass: "list-group pt-3" }, [
                _c("li", { staticClass: "list-group-item" }, [
                  _vm._v(" " + _vm._s(_vm.userEmails) + " "),
                ]),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-primary alignment pull-right",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  disabled: _vm.saving,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.save()
                  },
                },
              },
              [_vm._v(" Save Changes ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }